<template>
	<div class="userinfo">
		<div class="title">
			<h4>欢迎您，{{ name }}</h4>
		</div>
		<ul>
			<li><h5>服务vip时间</h5></li>
			<li><span>鲸落三国：</span><span>{{ vip }}</span></li>
		</ul>
	</div>
</template>

<script>
	import {getUserInfo} from "@/network/api";
	export default {
		name: "UserInfo",
		data() {
			return {
				name: "",
				vip: "",
			}
		},
		created() {
			if (this.$store.state.token) {
				getUserInfo(this.$store.state.token).then(
					res => {
						if (res.code != 200) {
							this.$store.commit('addToken', null)
							this.$router.replace("/user/login")
						} else {
							this.name = res.msg.name
							this.vip = res.msg.vip
						}
					}
				)
			}
		},
		beforeCreate() {
			if (!(this.$store.state.token)) {
				this.$router.replace("/user/login")
			}
		},
	}
</script>

<style scoped lang="less">
	.userinfo {
		margin: 2rem 1rem 0;
		>.title {
			//display: flex;
			height: 5rem;
			text-align: center;
			//background-color: #000;
		}
		>ul {
			>li {
				text-align: center;
				font-size: .8rem;
				>h5 {
					font-size: 1rem;
					margin-bottom: 1rem;
				}
			}
		}
	}
</style>
